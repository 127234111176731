<template>
  <div class="challenge m-5">
    <b-row class="text-center no-wrap">
      <b-col cols="2">
        <div class="title-btn">
          <span class="label">Testar</span>
        </div>
      </b-col>
      <b-col align-v="center" cols="8">
        <span class="title">Lesson 01</span>
      </b-col>
      <b-col cols="2">
        <div class="title-btn">
          <span class="label">Testar</span>
        </div>
      </b-col>
    </b-row>
    <hr>
    <div class="content">
      <span>Answer the following question:</span>
      <Listener />
      <img src="../assets/images/challenge-01.png" alt="challenge-01">
      <div class="chat-input">
          <div class="icons-container">
            <Microphone v-if="isLoading == false" :isListening="isListening" :toggleListening="toggleListening" class="mic"/>
            <div v-else class="text-center text-dark my-2">
              <b-spinner style="color: #115f63;" type="grow" label="Spinning"></b-spinner>
              <b-spinner style="color: #115f63;" type="grow" label="Spinning"></b-spinner>
              <b-spinner style="color: #115f63;" type="grow" label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Microphone from '@/components/forms/Microphone.vue'
import Listener from '@/components/forms/Listener.vue'
// import { api } from '@/services'
import { toast } from '@/utils'

export default {
  data: () => ({
    isLoading: false,
    isListening: false,
    mediaRecorder: null,
    recordedChunks: []
  }),
  components: { Microphone, Listener },
  computed: {
    // action () {
    //   return { options: { label: 'Home', icon: 'home' } }
    // }
  },
  methods: {
    toggleListening () {
      if (!this.isListening) {
        this.startRecording()
      } else {
        this.pauseRecording()
      }
    },
    startRecording () {
      navigator.mediaDevices.getUserMedia({ audio: true, sampleRate: 44100 })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream)

          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.recordedChunks.push(event.data)
            }
          }

          this.mediaRecorder.onstop = () => {
            /*  const audioBlob = new Blob(this.recordedChunks, { type: 'audio/wav', bitsPerSecond: 128000 }) */
            console.log('teste 1')
            if (window.location.search.length !== 0) {
              console.log('teste 2')
              const audioBlob = new Blob(this.recordedChunks, { type: 'audio/wav', bitsPerSecond: 128000 })
              this.sendAudioToApi(audioBlob)
            } else {
              console.log('teste 3')
              const audioBlob = new Blob(this.recordedChunks, { type: 'audio/wav', bitsPerSecond: 128000 })
              // Converter o Blob em uma string usando FileReader
              const reader = new FileReader()
              reader.onload = (event) => {
                const blobAsText = event.target.result
                localStorage.setItem('audioBlob', blobAsText) // Armazenar no localStorage
              }
              reader.readAsDataURL(audioBlob) // Leitura do Blob como uma URL de dados
            }
            console.log('teste 4')
            this.recordedChunks = []
          }
          this.mediaRecorder.start()
          this.isListening = true
        })
        .catch((error) => {
          toast(this, 'danger', 'Erro ao acessar o microfone:', error)
        })
    },
    pauseRecording () {
      this.mediaRecorder.stop()
      this.isListening = false
    },
    sendAudioToApi (audioBlob) {
      this.isLoading = true
      const formData = new FormData()
      formData.append('audio', audioBlob, 'recording.wav')
      formData.append('chat_key', window.location.search.split('=')[1])
      formData.append('voice', JSON.parse(localStorage.getItem('selectedVoice')))

      console.log(formData)
      console.log(audioBlob)

      // TODO: Send to API, and compare, both voices
    }
  }
}
</script>

<style lang="stylus" scoped>

@import '../style/*'

.challenge
  padding-left 5%
  padding-right 5%

  .title
    color black
    font-weight bold
    font-size 1.5rem

  hr
    margin-top 40px
    margin-bottom 40px

  .content
    display flex
    flex-direction column

    span
      font-size 1.3rem
      margin-bottom 40px

    img
      width 500
      align-self center
      margin-bottom 80px

    .chat-input
      align-self center
      margin-right 50px

    .mic
      width 20px
.title-btn
  margin-left auto
  background-color primary
  color white
  border-radius 8px
  padding 10px 12px
  cursor pointer
  transition 0.2s

  &:hover
      background-color alpha(primary, 0.8)

  span, .icon-btn
      vertical-align middle
      no-select()
</style>
